.Contact {
  padding-top: 64px;
  padding-bottom: 80px;
  color: #fff;

  @media (min-width: 992px) {
    padding-top: 94px;
    padding-bottom: 100px;
  }

  h1 {
    color: #9fb7b5;
    margin-bottom: 24px;

    @media (min-width: 992px) {
      font-size: 72px;
      text-align: center;
    }
  }

  .btn {
    width: 100%;

    @media (min-width: 992px) {
      width: 200px;
    }
  }

  .email {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 80px;
  }
}