.Hero {
  color: white;
  text-align: center;
  padding-bottom: 60px;

  @media (min-width: 992px) {
    padding-bottom: 0px;
  }

  .row {
    min-height: calc(100vh - 90px);
  }

  h1 {
    margin-bottom: 36px;

    @media (min-width: 992px) {
      font-size: 60px;
    }
  }

  p {
    font-size: 18px;
  }
}