.Applicable {
  padding-top: 64px;
  padding-bottom: 80px;
  color: white;
  font-size: 18px;

  @media (min-width: 992px) {
    padding-top: 94px;
    padding-bottom: 100px;
  }

  .top-p {
    font-size: 24px;
    color: #e7fbf9;
    font-weight: bold;
    margin-bottom: 1rem;
    text-transform: uppercase;

    @media (min-width: 992px) {
      margin-bottom: 64px;
    }
  }

  .top-small-border {
    margin-top: 30px;

    @media (min-width: 992px) {
      margin-top: 0px;
    }
  }

  h1 {
    margin-bottom: 24px;
    margin-top: 20px;
    font-size: 1.5rem;

    @media (min-width: 992px) {
      font-size: 2.5rem;
    }
  }

  .list-title {
    font-weight: bold;
    font-size: 18px;
    text-transform: uppercase;
  }

  ul {
    padding-left: 20px;
  }
}