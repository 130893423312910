.Advisors {
  padding-top: 64px;
  padding-bottom: 36px;
  background-color: #fff;

  @media (min-width: 992px) {
    padding-top: 94px;
    padding-bottom: 40px;
  }

  h1 {
    color: #9B9B9B;
    margin-bottom: 30px;

    @media (min-width: 992px) {
      font-size: 72px;
      margin-bottom: 60px;
    }
  }

  .top-title {
    font-size: 24px;
    font-weight: bold;
    color: #002A26;
    margin-bottom: 16px;
  }

  ul {
    padding-left: 20px;

    li {
      margin-bottom: 16px;
      font-size: 18px;
    }
  }

  .team-description {
    margin-bottom: 24px;

    @media (min-width: 992px) {
      margin-bottom: 60px;
    }
  }
}