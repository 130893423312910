.ScrollNav {
  padding-top: 8px;
  padding-bottom: 8px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  border-bottom: 1px solid #000;
  z-index: 2;
  opacity: 0.98 !important;
  animation: fadeIn 250ms ease-in-out;
  visibility: visible;

  .btn {
    width: 100%;
    margin-top: 12px;
    margin-bottom: 16px;

    @media (min-width: 992px) {
      margin-left: 15px;
      width: 120px;
      padding: 8px;
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }

  .custom-link {
    color: #000;
    font-weight: bold;
    font-size: 0.925rem;
    padding-top: 12px;

    @media (min-width: 992px) {
      padding-left: 15px !important;
      padding-right: 15px !important;
      padding-top: 8px;
    }

    &.custom-active {
      border-top: 4px solid black;
      margin-top: -26px;
      padding-top: 30px;
    }

    &.active {
      border: none;
    }
  }

  .button {
    &.custom-active {
      background: black;
    }
  }
}

.hidden-nav {
  padding-top: 8px;
  padding-bottom: 8px;
  position: fixed;
  top: -91px;
  left: 0;
  width: 100%;
  background: #fff;
  border-bottom: 1px solid #000;
  z-index: 2;
  opacity: 0.98 !important;
  animation: fadeOut 250ms ease-in-out;
  visibility: visible;

  .btn {
    width: 100%;
    margin-top: 12px;
    margin-bottom: 16px;

    @media (min-width: 992px) {
      margin-left: 15px;
      width: 120px;
      padding: 8px;
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }

  .custom-link {
    color: #000;
    font-weight: bold;
    font-size: 0.925rem;
    padding-top: 12px;

    @media (min-width: 992px) {
      padding-left: 15px !important;
      padding-right: 15px !important;
      padding-top: 8px;
    }

    &.custom-active {
      border-top: 4px solid black;
      margin-top: -26px;
      padding-top: 30px;
    }

    &.active {
      border: none;
    }
  }

  .button {
    &.custom-active {
      background: black;
    }
  }
}

.hidden-nav-no-animation {
  position: fixed;
  top: -100px;
  z-index: 1000;
  text-align: right;
}

@keyframes fadeOut {
  0% {
    opacity: 0.98;
    transform: translateY(100%);
  }
  100% {
    opacity: 0;
    transform: translateY(0px);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 0.98;
    transform: translateY(0px);
  }
}

.navbar-toggler {
  border-color: black !important;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0,0,0, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}