html {
  width: 100%;
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  overflow-x: hidden;
}

.btn {
  font-weight: bold;
  border-radius: 0px;
  border-width: 2px;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: bold;
  font-family: 'Playfair Display', serif;
}

.top-small-border {
  height: 6px;
  width: 44px;
  background-color: black;
  content: "";
  margin-bottom: 14px;

  &.blue {
    background-color: #54b1a8;
  }

  &.light {
    opacity: 0.49;
    background-color: #ffffff;
  }
}