.Navigation {
  padding-top: 8px;
  padding-bottom: 8px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  z-index: 2;
  border-bottom: 1px solid #000;

  img {
    filter: invert(1);
  }

  @media (min-width: 992px) {
    position: relative;
    background: transparent;
    border-bottom: none;

    img {
      filter: none;
    }
  }

  .btn {
    width: 100%;
    margin-top: 12px;
    margin-bottom: 16px;
    border-color: black;
    color: black;

    @media (min-width: 992px) {
      border-color: white;
      color: white;
      margin-left: 15px;
      width: 120px;
      padding: 8px;
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }

  .custom-link {
    font-weight: bold;
    font-size: 0.925rem;
    padding-top: 12px;
    color: black;

    &:first-child {
      padding-top: 24px
    }

    @media (min-width: 992px) {
      padding-left: 15px !important;
      padding-right: 15px !important;
      padding-top: 8px;
      color: white;

      &:first-child {
        padding-top: 8px
      }
    }
  }
}