.OurTechnology {
  background: #fff;
  padding-top: 64px;
  padding-bottom: 80px;

  @media (min-width: 992px) {
    padding-top: 80px;
    padding-bottom: 100px;
  }

  h1 {
    color: #9b9b9b;
    margin-bottom: 30px;

    @media (min-width: 992px) {
      font-size: 72px;
      margin-bottom: 58px;
    }
  }

  .not-header {
    color: #002a26;
    text-transform: uppercase;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 64px;
  }

  .diagram {
    text-align: center;
    margin-bottom: 64px;
  }

  .top-p {
    color: #5a5a5a;
    margin-bottom: 24px;

    @media (min-width: 992px) {
      margin-bottom: 1rem;
    }
  }

  .bottom-p {
    font-size: 18px;

    @media (min-width: 992px) {
      font-size: 24px;
    }
  }

  .separator {
    margin-top: 24px;
    border-bottom: 1px solid #d8d8d8;
    margin-bottom: 28px;
  }

  .advantages-diagram {
    text-align: center;
    padding-top: 44px;
  }
}